import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import { Posts } from "../components/Posts";

const PostsPage = ({ data }) => {
  const posts = data.allMdx.edges;
  return (
    <Layout>
      <SEO title="Posts" />
      <Posts data={posts} />
    </Layout>
  );
};

export default PostsPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___datePublished], order: DESC }) {
      edges {
        node {
          id
          timeToRead
          excerpt
          fields {
            slug
          }
          frontmatter {
            datePublished(formatString: "YYYY-MM-DD HH:mm:ss")
            title
            description
            tags
          }
        }
      }
    }
  }
`;
